<template>
  <div class="modal-wrapper">
    <div class="modal-wrap">
      <article>
        <div class="sub-title">
          <h3 v-if="boardType === 'msgList'">{{ $t('common.sendMsg') }}</h3>
          <h3 v-if="boardType === 'msgHeadList'">{{ $t('button.topslideMsg') }}</h3>
          <h3 v-if="boardType === 'msgJoinList'">{{ $t('button.joinMsg') }}</h3>
        </div>
        <div class="page-content">
          <div class="item-row-container">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.qTitle2') }}</div>
              <div class="item-content">
                <input type="text" v-model="msgReq.msgTitle" />
              </div>
            </div>
          </div>
          <div class="item-row-container" v-if="boardType === 'msgList'">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.memType') }}</div>
              <div class="item-content">
                <select v-model="msgReq.targetType">
                  <option value="">{{ $t('searchArea.entire') }}</option>
                  <option value="member">{{ $t('common.member') }}</option>
                  <option value="partner">{{ $t('searchArea.partner') }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="item-row-container" v-if="boardType === 'msgList' && !recieveId">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('common.memLevel') }}</div>
              <div class="item-content">
                <select v-model="msgReq.targetLevel">
                  <option value="">{{ $t('searchArea.userAll') }}</option>
                  <option value="1">1</option>
                  <option value="1">2</option>
                  <option value="1">3</option>
                  <option value="1">4</option>
                  <option value="1">5</option>
                </select>
              </div>
            </div>
          </div>
          <div class="item-row-container" v-if="boardType === 'msgList' || boardType === 'msgHeadList'">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('searchArea.specificSelect') }}</div>
              <div class="item-content">
                <input type="text" v-model="msgReq.recieveId" />
              </div>
            </div>
          </div>
          <div class="item-row-container" v-if="boardType === 'msgJoinList'">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.useYn') }}</div>
              <div class="item-content">
                <ui-toggle :textType="'default'" :checked="!joinReq.delYn" @setYn="setYn(joinReq)" />
              </div>
            </div>
          </div>
          <div class="item-row-container" v-if="boardType === 'msgList' || boardType === 'msgHeadList'">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.hidden') }}</div>
              <div class="item-content">
                <input type="checkbox" v-model="msgReq.hiddenYn" />
              </div>
            </div>
          </div>
          <div class="item-row-container">
            <div class="item-wrap">
              <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.content') }}</div>
              <div class="item-content"><textarea v-model="msgReq.msgDesc"></textarea></div>
            </div>
          </div>
          <div class="modal-btns">
            <a @click="send" v-if="boardType === 'msgList'">{{ $t('button.send') }}</a>
            <a @click="slideSave" v-if="boardType === 'msgHeadList'">{{ $t('button.save') }}</a>
            <a @click="joinSave" v-if="boardType === 'msgJoinList'">{{ $t('button.save') }}</a>
            <a @click="modalClose">{{ $t('button.close') }}</a>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>
<script>
import lodash from 'lodash'
import { msgList, msgSend, msgHeadSave, msgJoinSave, msgUpdate } from '@/api/member.js'
export default {
  name: 'msgModal',
  props: ['boardType', 'recieveId', 'targetType'],
  data () {
    return {
      levels: '',
      msgReq: {
        msgTitle: '',
        msgDesc: '',
        targetType: this.targetType,
        targetLevel: '',
        recieveId: this.recieveId,
        hiddenYn: false
      }
    }
  },
  methods: {
    async getLevels () {
      const data = await getCode('memLevel')
      this.levels = data
    },
    async send () {
      const req = lodash.cloneDeep(this.msgReq)
      const trigger = confirm('쪽지를 전송 하시겠습니까?')
      if (!trigger) {
        return
      }
      if (req.hiddenYn) {
        req.hiddenYn = 'Y'
      } else {
        req.hiddenYn = 'N'
      }
      const res = await msgSend(req)
      if (res.resultCode === '0') {
        alert('쪽지 전송 완료')
        this.msgReq = {
          msgTitle: '',
          msgDesc: '',
          targetType: '',
          targetLevel: '',
          rMemIdList: [],
          hiddenYn: false
        }
        this.modalClose()
      } else {
        alert('쪽지 전송 실패, 다시 시도해주세요.')
      }
    },
    modalClose () {
      this.$emit('modalClose', false)
    }
  }
}
</script>
<style scoped>
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
textarea {
  width: 100%;
  min-height: 134px;
  border: 1px solid #aaa;
  background: #fafafa;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 2px;
}
input[type="text"], input[type="number"], input[type="password"], select {
  border: solid 1px #aaa;
  background-color: #fafafa;
  width: 166px;
  height: 20px;}
.pt50 {
  padding-top: 50px;
}
.modal-wrapper {
  display: flex;
  font-size: 16px;
}
.page-contents-wrap {
  margin-bottom: 0;
}
.modal-wrap .item-title {
  min-width: 95px;
}
</style>
